<template>
  <div class="card p-3 shadow-sm">
    <template v-if="loading">
      <app-loading></app-loading>
    </template>
    <template v-else>
      <ci-list :entity="type" :entity_id="id" @resetCompanyInstructions="initialize(false)" ></ci-list>
    </template>
  </div>
</template>
<script>
import CIList from "../ci/CIList.vue";
import {mapActions, mapGetters} from "vuex";
import AppLoading from "../../elements/AppLoading.vue";

export default {
  components:{AppLoading, 'ci-list':CIList},
  data(){
    return {
      type:'psc',
      id:this.$route.params.id,
      loading:false,
    }
  },
  computed:{
    ...mapGetters(['companyInstructions'])
  },
  methods:{
    ...mapActions(['getCompanyInstructions','getAirCiTypeKeys']),
    async initialize(withLoading = false){
      if(withLoading){
        this.loading=true;
        await this.getCompanyInstructions({type:this.type,id:this.id});
        this.loading=false;
      }
      else {
        await this.getCompanyInstructions({type:this.type,id:this.id});
      }
    }
  },
  created() {
    this.initialize()
    this.getAirCiTypeKeys();
  }
}
</script>

<style scoped></style>
